.groupScorecard{
    .ui.progress{
        width: 100%;
        background: rgba(51, 208, 67, 0.25) !important;
        border-radius: 5px !important;
        margin-bottom: 10px !important;
        .bar{
            border-radius: 5px !important;
        }
    }
    .info{
        i{
            color: rgba(58, 77, 93, 0.5) !important;
            font-size: 14px;
        }
        color: #3A4D5D;
    }
    .result{
        color: #3A4D5D;
        font-weight: bold;
        font-size: 24px;
        text-align: center;
    }
    .learnerDist{
        border-top: 1px solid rgba(33, 150, 243, 0.25) !important;
    }

    .highcharts-color-0{
        fill: #326199 !important;
        stroke: #326199 !important;
    }
    .highcharts-color-1{
        fill: #48A5DE !important;
        stroke: #48A5DE !important;
    }
    .highcharts-color-2{
        fill: #F6CD73 !important;
        stroke: #F6CD73 !important;
    }
    .learnProgress{
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        padding-top: 30px !important;
    }
    .btnDown{
        text-align: center;
    }
    .progressBar{
        display: flex;
        flex-direction: row;
        b{
            font-size: 20px;
            margin: 10px;
        }
    }
    .indProgressHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        .ui.header{
            margin: 0 !important;
        }
        .ui.dropdown{
            margin-left: 10px;
        }
    }
    .individuList{
        .row:hover{
            background-color: rgba(196, 196, 196, 0.5);
            cursor: pointer;
            border-radius: 5px;
        }
        .row > .column{
            word-break: break-all !important;
        }
    }
}

.ui.statistic.groupProgressStatic{
    .label{
        word-wrap: break-word;
        font-size: smaller !important;
    }
    .value{
        word-break: break-all;
    }
}

#name_report{
    word-break: break-all !important;
}

#filter_date{
    width: 100%;
}

.scorecardInfo{
    position: absolute;
    top: 0;
    right: 0;
}