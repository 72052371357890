#mobile_body{
    background: radial-gradient(50% 50% at 50% 50%, #0AB3E9 0%, #147ADF 100%);
    min-height: 100vh;

    .ui.menu{
        background: transparent !important;
        border: none !important;
        box-shadow: none !important;
    }

    #logo_white{
        width: 90px;
    }

    .inverted{
        color: #F2F2F2 !important;
    }

    #btn_logout{
        font-size: 12px;
    }

    #download_report_segment{
        padding: 1em 1em;

        .ui.form{
            padding-bottom: 50px;
        }
    }

    .dr_header{
        line-height: 5px !important;
    }

    .ui.dropdown.selection{
        border: 1px solid rgba(34,36,38,.15) !important;
    }

    #footer{
        margin-top: 20px;

        .ui.grid{
            align-items: center;
        }
    }

    #period{
        .field{
            width: 100%;
        }
    }
}