@import "initial";

//Main typography settings
body, h1, h2, h3, h4, p{
  font-family: map-get($fontFamily, Roboto);
  color: colorDark(1);
  font-weight: map-get($fontWeight, bold);
  line-height: 150%;
}

//h1 styling
h1{
  font-size: map-get($fontSize, h1);
}

//h2 styling
h2{
  font-size: map-get($fontSize, h2);
  font-family: map-get($fontFamily, Roboto);
}

//h3 styling
h3{
  font-size: map-get($fontSize, h3);
}

//h4 styling
h4{
  font-size: map-get($fontSize, h4);
}

//body, p styling
body, p{
  font-size: map-get($fontSize, normal);
  font-weight: map-get($fontWeight, normal);
}

//Special for note font
.typography--note{
  font-size: map-get($fontSize, note);
}

//typography modifier
.typography{
  &--black{
    font-weight: map-get($fontWeight, black);
  }

  &--bold{
    font-weight: map-get($fontWeight, bold);
  }

  &--normal{
    font-weight: map-get($fontWeight, normal);
  }

  &--light{
    font-weight: map-get($fontWeight, light);
  }

  &--primary{
    color: colorDark(1);
  }

  &--secondary{
    color: colorDark(0.8);
  }

  &--tertiary{
    color: colorDark(0.5);
  }
}

//typography in the inverted block
.inverted{
  h1, h2, h3, h4, p{
    color: colorBright(1);
  }

  //typography midifier for inverted block
  .typography{
    &--primary{
      color: colorBright(1);
    }

    &--secondary{
      color: colorBright(0.7);
    }

    &--tertiary{
      color: colorBright(0.4);
    }
  }
}
