.blueone{
    background: #C7E4FD !important;
    border: none !important;
    box-shadow: none !important;
}
.contentCardWrapper{
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img{
        width: 209.88px !important;
        height: 100% !important;
    }

    .cardInfo{
        margin-left: 10px;
        p{
            line-height: 5px;
        }
        .ui.header{
            i{
                font-size: small;
                line-height: 10px;
            }
        }
        .extra{
            color: rgba(53, 73, 89, 0.8);
            font-weight: 500;
        }
        .footer{
            font-weight: 500;
            color: rgba(53, 73, 89, 0.6);
            font-size: smaller;
        }
    }
}

.assignedSubject{
    a{
        background: rgba(33, 150, 248, 0.25) !important;
        border-radius: 100px !important;
    }
}
.linkStatus{
    font-size: 12px !important;
    padding: 10px !important;
}

.headerTable{
    color: rgba(53, 73, 89, 0.8);
    font-weight: 500;
}
.assignAction{
    position: absolute;
    bottom: 0;

    #remove{
        color: #F44336;
    }
}
.modalCard{
    .ui.link.card{
        background: #FFFFFF !important;
        border: 1px solid #C7E5FC !important;
        box-shadow: 2px 3px 5px rgba(33, 150, 243, 0.25) !important;
        border-radius: 5px !important;
        padding: 10px !important;
    }
    .ui.dimmer{
        border-radius: 5px !important;
    }
}