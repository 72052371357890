@import "initial";

table.ui.table{
  //action table
  &.action{
    thead{
      th:last-child{
        text-align: right;
      }
    }

    tbody{
      td:last-child{
        text-align: right;
        white-space: nowrap;

        br + .ui.button{
          margin-top: 0.5em;
        }
      }
    }
  }
}
