@import "initial";

//Main button settings
.ui.button{
  font-family: map-get($fontFamily, Roboto);
  font-size: map-get($fontSize, normal);
  border-radius: 100px;
  font-weight: map-get($fontWeight, bold);
  padding: 12px 18px;
  @include transition();

  //Hover effect default
  &:hover{
    @include transition();
  }

  &:active{
    opacity: 0.8;
  }

  //Style for icon in the button
  .icon:not(.button){
    opacity: 1;
  }

  //Mixin to create repeatable button
  @mixin buttonMaker($colorFunction, $colorFunctionHover){
    background: $colorFunction;
    color: colorBright(1);
    font-weight: map-get($fontWeight, normal);
    box-shadow: 2px 2px 10px colorBlueDark(0.25);

    &:hover{
      background: $colorFunctionHover;
      box-shadow: 2px 2px 10px colorBlueDark(0.4);
    }
  }

  //Button modifier
  &.primary{
    @include buttonMaker(colorPrimary(45deg), colorPrimary(90deg));

    &.disabled{
      background: colorBlue(1) !important;
    }
  }

  &.secondary{
    background: colorBright(0);
    border: 1.5px solid colorBlue(1);
    color: colorBlue(1);

    &:hover{
      border: 1.5px solid colorCyan(1);
      color: colorCyan(1);
      background: transparent !important;
    }
  }

  &.tertiary{
    background: colorBright(0);
    color: colorBlue(1);
    margin-left: 0;
    margin-right: 0;

    &:hover{
      background: transparent !important;
    }
  }

  &.positive{
    @include buttonMaker(colorGreen(45deg), colorGreen(90deg));

    &.disabled{
      background: green !important;
    }
  }

  &.negative{
    @include buttonMaker(colorRed(45deg), colorRed(90deg));

    &.disabled{
      background: red !important;
    }
  }

  &.big{
    font-size: map-get($fontSize, h3);
    font-weight: map-get($fontWeight, normal);
    padding: 15px 25px;
  }

  &.small{
    font-size: map-get($fontSize, note);
    font-weight: map-get($fontWeight, normal);
    padding: 8px 15px;
  }
}

//Button in inverted segment
.inverted{
  .ui.button{
    @mixin invertedButton(){
      box-shadow: 2px 2px 10px colorBright(0.1);

      &:hover{
        box-shadow: 2px 2px 10px colorBright(0.2);
      }
    }

    &.primary{
      background: colorBright(1);
      color: colorBlue(1);
      &:hover{
        background: colorBright(0.9);
      }
      @include invertedButton();
    }

    &.secondary{
      background: colorBright(0);
      border: 1.5px solid colorBright(1);
      color: colorBright(1);

      &:hover{
        border: 1.5px solid colorBright(0.8);
        color: colorBright(0.8);
      }
    }

    &.tertiary{
      background: colorBright(0);
      color: colorBright(1);
      margin-left: 0;
      margin-right: 0;

      &:hover{
        color: colorBright(0.8);
      }
    }

    &.positive, &.negative{
      @include invertedButton();
    }
  }
}
