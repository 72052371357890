@import "initial";

//Main segment settings
@mixin segmentDefault(){
  background: colorBright(1);
  border: 1px solid colorBlue(0.25);
  box-sizing: border-box;
  box-shadow: 2px 3px 5px colorBlue(0.25);
  border-radius: 5px;
  font-size: map-get($fontSize, normal);
}

.ui.segment{
  @include segmentDefault();
  padding: 2em;

  //for raised segment
  &.raised{
    box-shadow: 5px 3px 20px colorBlue(0.25);
  }

  //inverted segment
  &.inverted{
    background: colorPrimary(45deg);
  }
}

.ui.segments{
  @include segmentDefault();

  .segment{
    border-top: 1px solid colorBlue(0);

    &.segment{
      &__header{
        font-size: 20px;
        font-weight: map-get($fontWeight, bold);
        color: colorDark(0.8);
        padding: 1em 1em;
        vertical-align: middle;

        &:before{
          position: absolute;
          content: '';
          top: 20%;
          left: 0px;
          height: 60%;
          width: 5px;
          background: colorPrimary(0deg);
          border-radius: 0 100px 100px 0;
        }

        &+.segment{
          padding-top: 1em;
        }
      }

      &__footer{
        border-top: 1px solid colorBlue(0.25);
        background: colorBlue(0.1);
        padding: 0.75em;

        &--basic{
          // border-top: 1px solid colorBlue(0.25);
          background: colorBright(0.25);
          padding: 0.75em;
        }
      }
    }
  }
}
