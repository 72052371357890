@import "initial";

//Here for custom CSS
.sc-cSHVUG.hbmRQv{
    width: 20%
}

.ui.button.floating.labeled.search.selection.dropdown.icon{
    .search{
        padding-left: 42px;
    }
}

.ui.divider{
  opacity: 0.5;

  &.half.hidden{
    margin: 0rem 0rem;
  }
}

.ui.checkbox{
  label{
    color: colorDark(0.8);
  }
}

#ladder{
    border-collapse: collapse;
    td{
        height: 300px;
        vertical-align: bottom;
        padding: 0px;
    }

    #podium0, #podium1, #podium2 {
        width: 150px;
        border-radius: 3px;

        h1{
            text-align: center;
            padding-top: 12%;
            font-size: 80px;
            color: #fff;
        }
    }
    #podium0 { /* gold */
        height: 250px;
        background: #fbbd08;
    }
    #podium1 { /* silver */
        height: 200px;
        background: #767676;
    }
    #podium2 { /* bronze */
        height: 150px;
        background: #a5673f;
    }
}

.addBorderNew{
    border-left: 3px solid #FBBD08 !important;
}

#suggested_style .ui.secondary.menu .item{
    border: 1px #03a9f469 solid;
}

.ui.menu .item.ui.dropdown{
    color: #fff;
}

#main_sidenav{
  height: 100vh;
  overflow: auto;
  padding: 15px;
  width: 300px;
  z-index: 999;
  // background: rgb(33,150,243);
  background: linear-gradient(354deg, rgba(33,150,243,1) 60%, rgba(0,212,255,1) 100%);

  .item{
    img{
      width: 150px;
      margin-bottom: .75em;
    }

    &.lalogo{
      &:hover{
        background: rgba(0,0,0,0);
      }
    }

    font-weight: 700;
    border-radius: 100px !important;

    &.active{
      background: rgba(0,0,0,0.25);
    }

    &:hover{
      color: rgba(255,255,255,1);
    }

  }
}

#mainGrid{
  #sidebarColumn{
    // background: red;
    // position: relative;
    // width: 100%;
  }
}

//head menu
.ui.borderless.menu.v2{
  padding: 0;
  box-shadow: 2px 3px 5px rgba(33, 150, 243, 0);
  border: 0;
  background: transparent;
  padding-left: 350px;
  padding-right: 100px;

  a.item.image{
    display: none;

    &+.item{
      padding-left: 0;
    }
  }

  .item{
    font-weight: 500;
  }

  .item.ui.dropdown {
      color: colorDark(0.65);
  }

  #clock{
    font-weight: 400;
    opacity: 0.7;
  }

  #clock+.item{
    background: rgba(33, 150, 243, 0.1);
    border-radius: 100px;
  }
}

.sidebarContainer{
  margin-top: 5px;
  margin-left: 7.5px;
  border-left: 1px solid rgba(255,255,255,0.5);
  padding-left: 10px;

  .item{
    font-weight: 500 !important;
  }

  &.studentSpecial{
    padding-top: 0.75em;
  }

  &:not(.studentSpecial){
    // padding-left: 0.25em;
    padding-top: 0.25em;
    .item{
      font-size: 14px;
      border-radius: 100px !important;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 5px;

      &.active{
        padding-left: 15px;
      }
    }
  }

  .ui.button{
    color: rgba(0,0,0,0.5);
    font-weight: 700;
  }
}

#scrollable_div{
  margin-bottom: 0.25em;
  .item{
    font-size: 14px;
    border-radius: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  background: rgba(255,255,255,0.1);
  border-radius: 5px;
}

.ui.vertical.menu .item > i.icon {
    width: 1.18em;
    float: inherit;
    margin: 0em 0.5em 0em 0em;
}

//footer
.ui.vertical.footer.segment.v2{
  background: rgba(33, 150, 243, 0.15);
  padding: 50px 25px 50px 350px;
  margin-top: 50px !important;
  color: colorDark(0.5);

  .five.wide.column{
    opacity: 1 !important;
  }

  a, h4, a i{
    color: colorDark(0.5) !important;
  }

  a .facebook{
    color: white !important;
    background-color: #3b5998 !important;
    box-shadow: none !important;
  }
  a .twitter{
    color: white !important;
    background-color: #00acee !important;
    box-shadow: none !important;
  }
  a .icon{
    color: white !important;
    box-shadow: none !important;
  }
  a .instagram{
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
  a .youtube{
    background-color: #FF0000;
  }
  a .linkedin{
    background-color: #0e76a8;
  }

  h4.inverted.header{
    font-weight: 700 !important;
  }

  a:hover{
    // text-decoration: underline;
    color: colorDark(0.65) !important;
  }
}

#goUpButton{
  border-radius: 5px;
}

#segmentHome{
  h2{
    margin-top: -20px;
  }

  .ui.grid{
    .column{
      table{
        border: 1px solid colorBlue(0.25);
        background: colorBlue(0.01);
        padding: 20px;
        color: colorDark(0.8);
        font-size: 15px;

        thead{
          tr{
            th{
              color: colorDark(0.8);

              &:first-child{
                width: 35px;
              }
            }
          }
        }
      }
    }
  }

  h3.header{
      font-family: map-get($fontFamily, Roboto);
      color: colorDark(0.8);
      margin-bottom: 20px;

      .icon{
        margin-right: 10px;
      }
  }
}

#mainContainer.v2{
  margin-top: 0 !important;
}

.filterDataSegment{
  border: 1px solid colorBlue(0.25);
  background: colorBlue(0.05);
  border-radius: 10px;

  h5{
    margin-top: 5px;
    font-family: 'Roboto';
  }

  .ui.labels{
    margin-top: 10px !important;

    .ui.label{
      font-family: 'Roboto';
      background: colorBlue(0.1);
      padding: 3px;
      border-radius: 100px;
      color: colorDark(0.65);
    }
  }

  &+.column{
    .filterToggleButton{
      border-radius: 0px 100px 100px 0px;
      position: relative;
      left: -14px;
      background: colorBlue(0.25);
      margin-right: 0px;
      color: colorDark(0.8) !important;
    }
  }
}

.filterContentSegment{

  .filterToggleButton{
    border-radius: 100px;
    background: colorBlue(0.25) !important;
    margin-right: 15px;
    color: colorDark(0.8) !important;
  }

  .ui.card{

    .meta{
      .ui.label{
        background: colorDark(0.25);
        padding: 5px 7px;
        border-radius: 100px;
        color: colorBright(1);
        margin-top: 15px;
      }
    }

    .description{
      div{
        margin-bottom: -5px;

        &.mainContent{
          font-size: 16px;
          margin-top: 2px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.segmentContentTitle{
  font-size: 24px;
  display: inline-block;
}

.buttonChangeGorupName{
  position: absolute;
}

.groupNameDropdown{
  // border: 0 !important;
  box-shadow: 2px 3px 5px rgba(33, 150, 243, 0) !important;
  padding-top: 15px !important;
  margin-top: 5px !important;

  input+div{
    font-size: 24px !important;
    color: colorDark(0.8);
  }

  &.group{
    padding: 3px !important;

    input+div{
      padding: 3px !important;
      font-size: 12px !important;
      color: colorDark(0.8);
    }
  }
}


//for datatable
.thereIsTable{
  .rdt_TableRow{
    .rdt_TableCell:last-child{
      div{
        .ui.button{
          margin-top: 5px;
          margin-bottom: 0px;

          &:last-child{
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.cardHeader{
  display: flex !important;
  flex-direction: row;
  flex-flow: wrap;
}

.extraCard{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 5px;

  span{
    font-size: 10px;
  }
}

.mainContent{
  tr{
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 5px;
  }

  td{
    word-break: break-all;
  }
}