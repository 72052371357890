@import "initial";

.ui.finder{
  display: block;
  background: colorBlue(0.1);
  padding: 1em;
  height: 500px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  .finder__column{
    background: transparent;
      width: 350px;
      height: 100%;
      display: inline-block;
      overflow-y: scroll;
      margin: 0;

    &.level{
      width: 225px;
    }
  }

  .finder__placeholder{
      display: inline;
    }

  .finder__item{
    background: colorBright(1);
    display: block;
    padding: 1em;
    overflow-x: hidden;
    cursor: pointer;
    border: 1px solid colorBlue(0.25);

    margin-bottom: 2px;

    &.active{
      background: colorBlue(0.25);

      .ui.button:not(.negative){
        background: colorBright(1);

        &:hover{
          &.green{
            background: #2ecc40;
          }

          &.blue{
            background: #54c8ff;
          }

          &.red{
            background: #ff5144;
          }
        }
      }
    }

    &.unclickable{
      cursor: default;
    }

    &+.ui.button{
      padding-top: 1em;
    }

    .finder__item__content{
      display: inline-block;
      overflow-x: hidden;
      margin-bottom: 0.2em;
      max-width: 250px;
      font-weight: map_get($fontWeight, bold);
      font-family: map_get($fontFamily, Roboto);
      color: colorDark(0.8);
      font-size: 18px;
      text-overflow: ellipsis;

      &.level{
        margin-bottom: 0.2em;
      }
    }

    .finder__item__label{
      display: inline-block;
      overflow-x: hidden;
      float: right;
      font-size: 12px;
      color: colorDark(0.6);
    }

    .finder__item__action{
      display: block;

      &.level{
        display: inline-block;
        overflow-x: hidden;
        float: right;
      }

      i.right.caret.icon{
        float: right;
        font-size: 20px;
        margin: 0;
        padding: 0;
        margin-right: -0.5em;
        color: colorDark(0.5);
      }
    }

    .finder__item__caret{
      display: inline-block;
      float: right;
      font-size: 20px;
    }

    .ui.button{
      font-size: 11px;
      padding: 0.5em 0.7em;
      border-radius: 5px;
      box-shadow: 1px 1px 5px colorBlueDark(0.25) !important;
    }
  }
}
