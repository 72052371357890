@import "initial";

.ui.card{
  background: colorBright(1);
  border: 1px solid colorBlue(0.5) !important;
  border-top: 3px solid colorBlue(0.5) !important;
  box-sizing: border-box;
  box-shadow: 2px 3px 5px colorBlue(0.1) !important;
  border-radius: 5px !important;
  font-size: map-get($fontSize, normal);
  padding: 7.5px !important;

  .image{
    background: transparent !important;
  }

  .header{
    color: colorDark(0.8) !important;
    font-size: 22px !important;
    font-family: 'Roboto' !important;
    word-wrap: break-word;

    a{
      color: colorDark(0.8) !important;
      text-decoration: underline;
      word-wrap: break-word;
    }
  }

  .content{
    border-top: 0px solid white !important;
  }
}
