@import "initial";

//Global form settings
@mixin formDefault(){
  font-family: map-get($fontFamily, Roboto);
  border: 1px solid colorBlue(0.35);
  box-sizing: border-box;
  box-shadow: inset 1px 1px 5px colorBlue(0.15);
  border-radius: 5px;
}

@mixin formDefaultHover(){
  border: 1px solid colorBlue(0.75);
  box-sizing: border-box;
  box-shadow: inset 1px 1px 5px colorBlue(0.25);
}

@mixin menuItem(){
  .menu{
    .item{
      &.selected{
        background: colorBlue(0.1);
      }

      &:hover{
        background: colorPrimary(45deg);
        color: colorBright(1);
      }
    }
  }
}

.ui.input{
  input{
    @include formDefault();

    &:focus{
      @include formDefaultHover();
    }
  }

  //For icon in input
  .icon{
    color: colorDark(0.5);
  }

  &.rounded{
    input{
      border-radius: 100px;
    }
  }

}

.ui.dropdown{

  //for selection
  &.selection{
    @include formDefault();

    &:hover{
      @include formDefaultHover();
    }
  }

  //label in multiple selection
  .label{
    background: colorBlue(0.25);

    &:hover{
      background: colorBlue(0.5);
    }

    &:active{
      background: colorBlue(0.75);
    }
  }

  @include menuItem();
}
