@import "initial";

.ui.feed{
  font-weight: map_get($fontWeight, normal);
  padding: 0.5em;

  .event{
    .content{
      .summary{
        color: colorDark(0.8);
      }
    }

    border-bottom: 1px solid colorBlue(0.25);
    padding: 1em;
  }
}
